import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import { Box, Chip, IconButton, Typography } from "@mui/material";
import { API_PATH } from "services/apipath";
import moment from "moment";
import { postApi } from "services/axiosInstance";
import Pagination from "components/Pagination/Pagination";
import Modal from "react-modal";
import { toast } from "react-toastify";
import { CloseOutlined } from "@mui/icons-material";
import TextToSpeechPlayer from "layouts/mock-history/QuestionPlayer";
import AudioPlayer from "./AudioPlayerAns";

function BillingInformation() {
  const [currentPage, setCurrentPage] = useState(1);
  const ITEMS_PER_PAGE = 10;

  const [history, setHistory] = useState([]);
  const [totalHistory, setTotalHistory] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalData, setModalData] = useState({});
  const [showAnswer, setShowAnswer] = useState(false);

  const [feedbackModal, setFeedbackModal] = useState(false);
  const [feedbutton, setFeedbackButton] = useState(true);
  const [feedback, setfeedback] = useState("");

  const getQuestionsList = async () => {
    setIsLoading(true);
    try {
      const res = await postApi(`${API_PATH.STUDENTS.GET_ATTEMPT_PRACTICE}`, {
        page: currentPage,
        limit: ITEMS_PER_PAGE,
      });

      if (res.status === 200 && res.data && res.data.data) {
        setHistory(res.data.data.attempts || []);
        setTotalHistory(res.data.data.totalData || 0);
      } else {
        console.error("Failed to fetch attempt history:", res);
        setHistory([]);
        setTotalHistory(0);
      }
    } catch (error) {
      console.error("Error fetching attempt history:", error);
      setHistory([]);
      setTotalHistory(0);
    } finally {
      setIsLoading(false);
    }
  };

  // Fetch questions whenever the page changes or the component mounts
  useEffect(() => {
    getQuestionsList();
  }, [currentPage]);

  // Calculate total pages based on total questions and ITEMS_PER_PAGE
  const totalPages = Math.ceil(totalHistory / ITEMS_PER_PAGE);

  const handlePageChange = (newPage) => {
    // Ensure the new page is within valid range
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  const processScore = (attempt) => {
    try {
      if (Array.isArray(attempt) && attempt.length === 1 && typeof attempt[0] === "string") {
        try {
          const parsedValue = JSON.parse(attempt[0]);

          if (Array.isArray(parsedValue)) {
            return parsedValue.length;
          } else if (typeof parsedValue === "object" && parsedValue !== null) {
            return Object.keys(parsedValue).length;
          } else {
            console.warn("Parsed value is neither an array nor an object:", parsedValue);
            return 1;
          }
        } catch (parseError) {
          return attempt.length;
        }
      } else if (Array.isArray(attempt)) {
        return attempt.length;
      } else if (typeof attempt === "object" && attempt !== null) {
        return Object.keys(attempt).length;
      } else {
        console.warn("Unexpected format:", attempt);
        return 0;
      }
    } catch (error) {
      console.error("Failed to process attempt:", error);
      return 0;
    }
  };

  const calculateScore = (attempt) => {
    // Single Answer, Multiple Answers, etc.
    if (attempt.module_subcategory?.includes('Single Answer') || 
        attempt.module_subcategory?.includes('Multiple Answers') || 
        attempt.module_subcategory?.includes('Highlight Summary') || 
        attempt.module_subcategory?.includes('Missing Word') ||
        attempt.module_subcategory?.includes('Reorder Paragraph')) {
      const userAnswers = attempt.answers || [];
      const correctAnswers = attempt.correctAnswers || [];
      
      if (attempt.module_subcategory?.includes('Multiple Answers')) {
        // For Multiple Answers, count correct selections without penalizing order
        const correctCount = userAnswers.filter(ans => correctAnswers.includes(ans)).length;
        return { score: correctCount, total: correctAnswers.length };
      } else {
        // For other types, keep index-based comparison
        const correctCount = userAnswers.filter((ans, idx) => ans === correctAnswers[idx]).length;
        return { score: correctCount, total: correctAnswers.length };
      }
    }
    // RW Fill in Blanks
    else if (attempt.module_subcategory?.includes('RW Fill in Blanks')) {
      try {
        const userAnswers = JSON.parse(attempt.answers[0]);
        const correctAnswers = JSON.parse(attempt.correctAnswers[0]);
        const correctCount = correctAnswers.filter((ans, idx) => ans === userAnswers[idx]).length;
        return { score: correctCount, total: attempt.questionId.rwF_ans.length };
      } catch (e) {
        return { score: 0, total: 0 };
      }
    }
    // Fill in The Blanks
    else if (attempt.module_subcategory?.includes('Fill in The Blanks') || 
             attempt.module_subcategory?.includes('Fill in Blanks')) {
      try {
        const answers = JSON.parse(attempt.answers[0]);
        const correctCount = Object.values(answers).filter(val => val === true).length;
        return { score: correctCount, total: attempt.questionId.FillInTheBlanksAnswers.length };
      } catch (e) {
        return { score: 0, total: 0 };
      }
    }
    // Incorrect Words
    else if (attempt.module_subcategory?.includes('Incorrect Words')) {
      try {
        const userAnswers = JSON.parse(attempt.answers[0]);
        const correctAnswers = JSON.parse(attempt.correctAnswers[0]);
        return { score: correctAnswers.length, total: attempt.questionId.mcq_ans.length };
      } catch (e) {
        return { score: 0, total: 0 };
      }
    }
    // Write From Dictation
    else if (attempt.module_subcategory?.includes('Write From Dictation')) {
      return { score: attempt.score?.overall || 0, total: attempt.correctAnswers[0].split(' ').length || 10};
    }
    // Short Answer
    else if (attempt.module_subcategory?.includes('Short Answer')) {
      return { score: attempt.score?.overall || 0, total: 1 };
    }
    // Speaking modules
    else if (attempt.module_type?.includes('Speaking') || 
             attempt.module_subcategory?.includes('Describe Image') ||
             attempt.module_subcategory?.includes('Retell Lecture') ||
             attempt.module_subcategory?.includes('Respond to Situation')) {
      const score = Math.floor((attempt.score?.content + attempt.score?.pronunciation + attempt.score?.fluency) / 3);
      return { score, total: 90 };
    }
    // Writing modules
    else if (attempt.module_type?.includes('Writing') || 
             attempt.module_type?.includes('Listening') ||
             attempt.module_subcategory?.includes('Essay') ||
             attempt.module_subcategory?.includes('Summarize Text') ||
             attempt.module_subcategory?.includes('Write Email')) {
      return { score: attempt.score?.overall || 0, total: attempt.score?.total || 9 };
    }
    // Default case
    else {
      const correctCount = processScore(attempt.correctAnswers);
      const totalCount = processScore(attempt.answers);
      return { score: correctCount, total: totalCount };
    }
  };

  const openModal = (data) => {
    console.log(JSON.stringify(data));
    console.log(data)

    setModalIsOpen(true);
    setModalData(data);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setModalData([]);
    setfeedback("");
    setFeedbackButton(true);
  };

  const submitFeedback = async () => {
    // setLoading(true);
    // try {
    //   const res = await postApi(`${API_PATH.ADMIN.FEEDBACK}`, {
    //     studentId: attempts.studentId._id,
    //     fullTestId: attempts.fullTestId._id,
    //     questionId: modalData.questionId._id,
    //     feedback: feedback,
    //   });
    //   if (res.status === 200) {
    //     toast.success("Feedback added successfully");
    //     setFeedbackModal(false);
    //     setFeedbackButton(false);
    //   } else {
    //     throw new Error("Failed to fetch data");
    //   }
    // } catch (err) {
    //   console.log(err, "Error in data ");
    // } finally {
    //   setLoading(false);
    // }
  };

  return (
    <Card id="attempt-history">
      <SoftBox pt={1} px={2}>
        <SoftTypography variant="h5" fontWeight="medium">
          Attempt History
        </SoftTypography>
      </SoftBox>

      <SoftBox pt={1} pb={2} px={2}>
        <SoftBox
          component="ul"
          display="flex"
          flexDirection="column"
          p={0}
          m={0}
          sx={{
            height: '70vh',
            overflowY: 'scroll'
          }}
        >
          {isLoading ? (
            <SoftTypography variant="body2" textAlign="center">
              Loading attempts...
            </SoftTypography>
          ) : history.length === 0 ? (
            <SoftTypography variant="body2" textAlign="center">
              No attempt history found.
            </SoftTypography>
          ) : (
            history.map((attempt, index) => (
              <SoftBox
                key={index}
                component="li"
                mb={3}
                mx={1}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                p={2}
                borderRadius="lg"
                sx={{
                  backgroundColor: "#F8F9FA",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                }}
              >
                <SoftBox display="flex" flexDirection="column">
                  <SoftTypography
                    fontSize="15px"
                    fontWeight="regular"
                    color="black"
                  >
                    Attempted:{" "}
                    {moment(attempt.attemptedAt).format("DD-MM-YYYY hh:mm a")}
                    <br />
                  </SoftTypography>
                  <SoftTypography
                    mt={1}
                    fontSize="15px"
                    fontWeight="regular"
                    color="black"
                  >
                    Attempted Count: {attempt.attemptCount}
                  </SoftTypography>

                  {/* Module Type and Subcategory */}
                  <SoftBox display="flex" gap={1} mt={1} flexWrap="wrap" alignItems="center">
                    {attempt.module_type && attempt.module_type.length > 0 && (
                      <Chip
                        label={attempt.module_type[0]}
                        size="small"
                        color="primary"
                        variant="outlined"
                        sx={{ fontSize: '15px', padding: "15px" }}
                      />
                    )}
                    {attempt.module_subcategory && attempt.module_subcategory.length > 0 && (
                      <Chip
                        label={attempt.module_subcategory[0]}
                        size="small"
                        color="secondary"
                        variant="outlined"
                        sx={{ fontSize: '15px', padding: "15px" }}
                      />
                    )}
                    {attempt.questionId.question_title && (
                      <Chip
                        label={attempt.questionId.question_title}
                        size="small"
                        color="info"
                        variant="outlined"
                        sx={{
                          fontSize: '15px',
                          padding: "15px",
                          maxWidth: '200px',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis'
                        }}
                      />
                    )}
                  </SoftBox>

                  {/* <SoftTypography
                    variant="button"
                    fontWeight="medium"
                    color="error"
                    mt={1}
                  >
                    {attempt.questionId.question_title
                      ? attempt.questionId.question_title
                      : `#${attempt.questionId._id}`}
                  </SoftTypography> */}
                </SoftBox>

                <SoftBox display="flex" alignItems="center">
                  <Chip
                    label={(() => {
                      const { score, total } = calculateScore(attempt);
                      return `${score}/${total}`;
                    })()}
                    color={
                      (() => {
                        const { score, total } = calculateScore(attempt);
                        return score >= total ? "success" : "error";
                      })()
                    }
                    variant="outlined"
                    sx={{
                      color: "white",
                      borderColor: (() => {
                        const { score, total } = calculateScore(attempt);
                        return score >= total ? "#4CAF50" : "#F44336";
                      })(),
                      mr: 2,
                    }}
                    onClick={() => openModal(attempt)}
                  />
                </SoftBox>
              </SoftBox>
            ))
          )}
        </SoftBox>
      </SoftBox>
      {totalPages > 1 && (
        <Box
          sx={{
            p: 2,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderTop: "1px solid rgba(255, 255, 255, 0.1)",
            bgcolor: "#1a1a2e",
          }}
        >
          <Pagination
            totalPages={totalPages}
            currentPage={currentPage}
            onPageChange={handlePageChange}
          />
        </Box>
      )}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Read Aloud Modal"
        style={{
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "#2d2f3a",
            color: "#d1d5db",
            borderRadius: "8px",
            padding: "20px",
            width: "90vw",
          },
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.87)",
            zIndex: 10000,
          },
        }}
      >
        <div style={{ textAlign: "center" }}>
          <div className="d-flex justify-content-between">
            <Typography
              variant="h5"
              sx={{ color: "#d1d5db", marginBottom: "20px" }}
            >
              {modalData.module_subcategory}
            </Typography>

            <IconButton>
              <CloseOutlined
                onClick={closeModal}
                sx={{
                  color: "red",
                }}
              />
            </IconButton>
          </div>

          <div className="d-flex align-items-center justify-content-evenly mt-3">
            <div className=" ">
              {modalData.module_subcategory === "Describe Image" && (
                <img
                  src={`${process.env.REACT_APP_BASE_URL}/${modalData.questionId.image}`}
                  width={300}
                  alt=""
                />
              )}
              {/* Scoreing section  */}
              <div
                style={{
                  // display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "15px",
                }}
              >          
              </div>
              
            </div>
          </div>

          {/* TextToSpeech for Listening module type */}
          {modalData?.module_type?.includes('Listening') && (
            <div style={{ 
              marginBottom: "20px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}>
              <TextToSpeechPlayer
                text={modalData?.questionId?.audio_text || ""}
              />
            </div>
          )}

          {/* New Listening Single/Multiple Answer UI */}
          {(
            modalData?.module_subcategory?.includes('Single Answer') || 
            modalData?.module_subcategory?.includes('Multiple Answers') || 
            modalData?.module_subcategory?.includes('Highlight Summary') || 
            modalData?.module_subcategory?.includes('Missing Word') ||
            modalData?.module_subcategory?.includes('Reorder Paragraph')
          ) && (
            <div className="listening-answer" style={{ 
              marginTop: "30px",
              padding: "20px",
              backgroundColor: "rgba(255, 255, 255, 0.05)",
              borderRadius: "8px",
              width: "100%",
              margin: "0 auto",
              fontSize: "px"
            }}>
              {/* Add Reading Text Display */}
              {modalData?.module_type?.includes('Reading') && modalData?.questionId?.long_text_paragraph && (
                <div style={{ 
                  marginBottom: "20px",
                  padding: "15px",
                  backgroundColor: "rgba(255, 255, 255, 0.03)",
                  borderRadius: "8px",
                  border: "1px solid rgba(209, 213, 219, 0.3)"
                }}>
                  <Typography sx={{ 
                    color: "#d1d5db", 
                    textAlign: "left",
                    whiteSpace: "pre-wrap",
                    lineHeight: "1.6",
                    fontSize: "15px"
                  }}>
                    {modalData.questionId.long_text_paragraph}
                  </Typography>
                </div>
              )}

              {/* Reorder Paragraph UI */}
              {modalData?.module_subcategory?.includes('Reorder Paragraph') && (
                <div style={{ marginBottom: "20px" }}>
                  {/* Score Display at Top */}
                  <Typography variant="h6" sx={{ 
                    color: "#d1d5db", 
                    marginBottom: "20px", 
                    textAlign: "center",
                    fontSize: "24px"
                  }}>
                    Score: {modalData.answers?.filter((ans, idx) => 
                      modalData.correctAnswers[idx] === ans).length}/{modalData.correctAnswers?.length}
                  </Typography>

                  {/* Side by Side Container */}
                  <div style={{ 
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr",
                    gap: "20px",
                  }}>
                    {/* Response Column */}
                    <div style={{
                      backgroundColor: "rgba(255, 255, 255, 0.03)",
                      borderRadius: "8px",
                      padding: "16px",
                    }}>
                      <Typography sx={{ 
                        color: "#d1d5db",
                        marginBottom: "16px",
                        textAlign: "center",
                        fontWeight: "500"
                      }}>
                        Response
                      </Typography>
                      <div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
                        {modalData?.answers?.map((answerIndex, index) => {
                          const choice = modalData.questionId.choices[parseInt(answerIndex)];
                          return (
                            <div 
                              key={index}
                              style={{
                                padding: "12px 16px",
                                borderRadius: "4px",
                                backgroundColor: "rgba(255, 255, 255, 0.05)",
                                border: "1px solid rgba(209, 213, 219, 0.2)",
                              }}
                            >
                              <Typography 
                                sx={{ 
                                  color: "#d1d5db",
                                  textAlign: "left",
                                  fontSize: "14px",
                                  lineHeight: "1.5"
                                }}
                              >
                                {choice.choice_text}
                              </Typography>
                            </div>
                          );
                        })}
                      </div>
                    </div>

                    {/* Answer Column */}
                    <div style={{
                      backgroundColor: "rgba(255, 255, 255, 0.03)",
                      borderRadius: "8px",
                      padding: "16px",
                    }}>
                      <Typography sx={{ 
                        color: "#d1d5db",
                        marginBottom: "16px",
                        textAlign: "center",
                        fontWeight: "500"
                      }}>
                        Answer
                      </Typography>
                      <div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
                        {modalData?.correctAnswers?.map((answerIndex, index) => {
                          const choice = modalData.questionId.choices[parseInt(answerIndex)];
                          return (
                            <div 
                              key={index}
                              style={{
                                padding: "12px 16px",
                                borderRadius: "4px",
                                backgroundColor: "rgba(76, 175, 80, 0.1)",
                                border: "1px solid rgba(76, 175, 80, 0.3)",
                              }}
                            >
                              <Typography 
                                sx={{ 
                                  color: "#d1d5db",
                                  textAlign: "left",
                                  fontSize: "14px",
                                  lineHeight: "1.5"
                                }}
                              >
                                {choice.choice_text}
                              </Typography>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {/* Original Listening UI */}
              {!modalData?.module_subcategory?.includes('Reorder Paragraph') && (
                <>
                  <div style={{ marginBottom: "20px" }}>
                    <Typography variant="h6" sx={{ color: "#d1d5db", marginBottom: "15px", textAlign: "left" }}>
                      {modalData?.questionId?.question_text}
                    </Typography>
                  </div>

                  <div style={{ display: "flex", flexDirection: "column", gap: "12px" }}>
                    {modalData?.questionId?.choices?.map((choice, index) => {
                      const choiceLabel = String.fromCharCode(65 + index);
                      const isSelected = modalData.answers?.includes(choiceLabel);
                      const isCorrect = modalData.correctAnswers?.includes(choiceLabel);
                      
                      // Show styling based on different conditions
                      const shouldHighlight = isSelected || (showAnswer && isCorrect);
                      const isIncorrectSelection = isSelected && !isCorrect;
                      
                      return (
                        <div 
                          key={choice._id}
                          style={{
                            padding: "12px 16px",
                            borderRadius: "8px",
                            backgroundColor: shouldHighlight
                              ? (isSelected 
                                ? (isCorrect ? "rgba(76, 175, 80, 0.1)" : (showAnswer ? "transparent" : "rgba(244, 67, 54, 0.1)"))
                                : "rgba(76, 175, 80, 0.1)") // for correct answers when showAnswer is true
                              : "transparent",
                            border: `1px solid ${shouldHighlight
                              ? (isSelected 
                                ? (isCorrect ? "#4CAF50" : (showAnswer ? "rgba(209, 213, 219, 0.3)" : "#F44336"))
                                : "#4CAF50") // for correct answers when showAnswer is true
                              : "rgba(209, 213, 219, 0.3)"}`,
                            display: "flex",
                            alignItems: "flex-start",
                            cursor: "default",
                            transition: "all 0.2s ease",
                            opacity: shouldHighlight ? 1 : 1,
                            width: "100%",
                            wordBreak: "break-word"
                          }}
                        >
                          <Typography 
                            sx={{ 
                              color: shouldHighlight
                                ? (isSelected 
                                  ? (isCorrect ? "#4CAF50" : (showAnswer ? "#d1d5db" : "#F44336"))
                                  : "#4CAF50") // for correct answers when showAnswer is true
                                : "#d1d5db",
                              marginRight: "12px",
                              fontWeight: "500",
                              flexShrink: 0
                            }}
                          >
                            {choiceLabel}.
                          </Typography>
                          <Typography 
                            sx={{ 
                              color: shouldHighlight
                                ? (isSelected 
                                  ? (isCorrect ? "#4CAF50" : (showAnswer ? "#d1d5db" : "#F44336"))
                                  : "#4CAF50") // for correct answers when showAnswer is true
                                : "#d1d5db",
                              textAlign: "left",
                              flex: 1,
                              wordBreak: "break-word",
                              whiteSpace: "pre-wrap"
                            }}
                          >
                            {choice.choice_text}
                          </Typography>
                        </div>
                      );
                    })}
                  </div>

                  <div style={{ 
                    marginTop: "20px", 
                    display: "flex", 
                    justifyContent: "space-between",
                    alignItems: "center"
                  }}>
                    <div style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px"
                    }}>
                      <Typography sx={{ color: "#d1d5db", fontSize: "0.9rem" }}>
                        Score: 
                      </Typography>
                      <Chip
                        label={`${modalData.answers?.filter(ans => 
                          modalData.correctAnswers?.includes(ans)).length || 0}/${modalData.correctAnswers?.length || 0}`}
                        color={modalData.answers?.filter(ans => 
                          modalData.correctAnswers?.includes(ans)).length === modalData.correctAnswers?.length ? "success" : "error"}
                        variant="outlined"
                        sx={{
                          fontSize: "16px",
                          padding: "15px",
                          borderWidth: "2px"
                        }}
                      />
                    </div>

                    <div style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px"
                    }}>
                      <Typography sx={{ color: "#d1d5db", fontSize: "0.9rem" }}>
                        See Answer
                      </Typography>
                      <div className="form-check form-switch">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          role="switch"
                          id="seeAnswerSwitch"
                          checked={showAnswer}
                          onChange={(e) => setShowAnswer(e.target.checked)}
                          style={{
                            width: "40px",
                            height: "20px",
                            cursor: "pointer",
                            backgroundColor: showAnswer ? "#4CAF50" : "#d1d5db"
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          )}
            
          {/* New Write From Dictation UI */}
          {modalData?.module_subcategory?.includes('Write From Dictation') && (
            <div className="write-from-dictation" style={{ 
              marginTop: "30px",
              padding: "20px",
              backgroundColor: "rgba(255, 255, 255, 0.05)",
              borderRadius: "8px",
              width: "100%",
              margin: "0 auto"
            }}>
              <div style={{ marginBottom: "20px" }}>
                <Typography variant="h6" sx={{ color: "#d1d5db", marginBottom: "10px", textAlign: "left" }}>
                  Your Response:
                </Typography>
                <div style={{
                  padding: "15px",
                  borderRadius: "8px",
                  backgroundColor: "rgba(255, 255, 255, 0.03)",
                  border: "1px solid rgba(209, 213, 219, 0.3)",
                }}>
                  <Typography sx={{ color: "#d1d5db", textAlign: "left", whiteSpace: "pre-wrap" }}>
                    {modalData?.stringAns || "No response provided"}
                  </Typography>
                </div>
              </div>

              <div style={{ marginBottom: "20px" }}>
                <Typography variant="h6" sx={{ color: "#d1d5db", marginBottom: "10px", textAlign: "left" }}>
                  Correct Answer:
                </Typography>
                <div style={{
                  padding: "15px",
                  borderRadius: "8px",
                  backgroundColor: "rgba(255, 255, 255, 0.03)",
                  border: "1px solid rgba(209, 213, 219, 0.3)",
                }}>
                  <Typography sx={{ color: "#d1d5db", textAlign: "left", whiteSpace: "pre-wrap" }}>
                    {modalData?.correctAnswers?.[0] || "No answer available"}
                  </Typography>
                </div>
              </div>

              <div style={{ 
                marginTop: "20px", 
                display: "flex", 
                justifyContent: "flex-start",
                alignItems: "center",
                gap: "10px"
              }}>
                <Typography sx={{ color: "#d1d5db", fontSize: "0.9rem" }}>
                  Score: 
                </Typography>
                <Chip
                  label={`${modalData?.score?.overall || 0}/ ${modalData?.correctAnswers?.[0]?.split(' ').length || 10}`}
                  variant="outlined"
                  sx={{
                    fontSize: "16px",
                    padding: "15px",
                    borderWidth: "2px",
                    color: "#d1d5db",
                    borderColor: "rgba(209, 213, 219, 0.5)",
                    backgroundColor: "rgba(255, 255, 255, 0.03)"
                  }}
                />
              </div>
            </div>
          )}
            
          {/* New Short Answer UI */}
          {modalData?.module_subcategory?.includes('Short Answer') && (
            <div className="short-answer" style={{ 
              marginTop: "30px",
              padding: "20px",
              backgroundColor: "rgba(255, 255, 255, 0.05)",
              borderRadius: "8px",
              width: "100%",
              margin: "0 auto"
            }}>
              <div style={{ display: "flex", gap: "30px" }}>
                {/* Left Section - Scores and Audio */}
                <div style={{ flex: "0 0 300px" }}>
                  {/* Score Display */}
                  <Typography variant="h6" sx={{ 
                    color: "#d1d5db", 
                    marginBottom: "20px",
                    fontSize: "24px",
                    fontWeight: "500",
                    textAlign: "center"
                  }}>
                    Score : {modalData?.score?.overall || 0}/1
                  </Typography>
                  
                  {/* Content Score Bar */}
                  <div style={{ 
                    display: "flex", 
                    alignItems: "center",
                    marginBottom: "20px",
                    gap: "10px"
                  }}>
                    <Typography sx={{ 
                      color: "#d1d5db", 
                      minWidth: "80px",
                      fontSize: "14px"
                    }}>
                      Content {modalData?.score?.content || 0}/1
                    </Typography>
                    <Box sx={{
                      flex: 1,
                      height: "6px",
                      bgcolor: "rgba(255, 255, 255, 0.1)",
                      borderRadius: "3px",
                      position: "relative"
                    }}>
                      <Box sx={{
                        position: "absolute",
                        left: 0,
                        top: 0,
                        height: "100%",
                        width: `${(modalData?.score?.content || 0) * 100}%`,
                        bgcolor: "#00E676",
                        borderRadius: "3px"
                      }} />
                    </Box>
                  </div>

                  {/* Audio Players */}
                  <div style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
                    <div>
                      <Typography sx={{ color: "#d1d5db", fontSize: "14px", marginBottom: "8px" }}>Question</Typography>
                      <TextToSpeechPlayer text={modalData?.questionId?.audio_text || ""} />
                    </div>
                    {modalData?.score.text && (
                      <div>
                      <Typography sx={{ color: "#d1d5db", fontSize: "14px", marginBottom: "8px" }}>Response</Typography>
                      <AudioPlayer src={`${process.env.REACT_APP_BASE_URL}/${modalData?.answer_audio}`} />
                    </div>
                    )}
                  </div>
                </div>

                {/* Right Section - Text Display */}
                <div style={{ 
                  flex: 1,
                  backgroundColor: "rgba(255, 255, 255, 0.03)",
                  borderRadius: "8px",
                  padding: "20px",
                  minHeight: "300px",
                  display: "flex",
                  flexDirection: "column",
                  gap: "20px"
                }}>
                  {/* User Response */}
                  <Typography sx={{ 
                    color: "#d1d5db", 
                    fontSize: "18px",
                    fontWeight: "500"
                  }}>
                    {modalData?.score?.text || "No response provided"}
                  </Typography>

                  {/* Question and Answer */}
                  <div>
                    <Typography sx={{ 
                      color: "#d1d5db", 
                      fontSize: "16px",
                      marginBottom: "8px"
                    }}>
                      {modalData?.questionId?.audio_text || "No question available"}
                    </Typography>
                    <Typography sx={{ 
                      fontSize: "16px"
                    }}>
                      {'=>'} <span style={{ color: "#4CAF50" }}>{modalData?.questionId?.answer?.split('=')[1] || "No answer available"}</span>
                    </Typography>
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* Speaking - Repeat Sentence UI */}
          {(modalData?.module_type?.includes('Speaking') 
          && 
          modalData?.module_subcategory?.includes('Repeat Sentence') ||
          modalData?.module_subcategory?.includes('Read Aloud') ||
          modalData?.module_subcategory?.includes('Describe Image') ||
          modalData?.module_subcategory?.includes('Retell Lecture') ||
          modalData?.module_subcategory?.includes('Respond to Situation'))          
          && (
            <div className="speaking-repeat-sentence" style={{ 
              marginTop: "30px",
              padding: "20px",
              backgroundColor: "rgba(255, 255, 255, 0.05)",
              borderRadius: "8px",
              width: "100%",
              margin: "0 auto"
            }}>
              {/* Question Image for Describe Image */}
              

              <div style={{ display: "flex", gap: "30px" }}>
                {/* Left Section - Scores and Audio */}
                <div style={{ flex: "0 0 300px" }}>
                  {/* Score Display */}
                  {(
                    modalData?.module_subcategory?.includes('Describe Image')
                    || modalData?.module_subcategory?.includes('Respond to Situation')
                  ) && modalData?.questionId?.image && (
                  <div style={{ 
                    marginBottom: "20px",
                    textAlign: "center"
                  }}>
                      <img
                        src={`${process.env.REACT_APP_BASE_URL}/${modalData.questionId.image}`}
                        alt="Question Image"
                        style={{
                          maxWidth: "100%",
                          maxHeight: "300px",
                          borderRadius: "8px",
                          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)"
                        }}
                      />
                    </div>
                  )}
                  <Typography variant="h6" sx={{ 
                    color: "#d1d5db", 
                    marginBottom: "20px",
                    fontSize: "24px",
                    fontWeight: "500"
                  }}>
                    Score : {Math.floor((modalData?.score?.content + modalData?.score?.pronunciation + modalData?.score?.fluency) / 3)}/90.0
                  </Typography>

                  {/* Score Bars */}
                  <div style={{ marginBottom: "30px" }}>
                    {/* Content Score Bar */}
                    <div style={{ marginBottom: "15px" }}>
                      <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "5px" }}>
                        <Typography sx={{ color: "#d1d5db", fontSize: "14px" }}>Content</Typography>
                        <Typography sx={{ color: "#d1d5db", fontSize: "14px" }}>{modalData?.score?.content || 0}/90</Typography>
                      </div>
                      <Box sx={{
                        height: "8px",
                        bgcolor: "rgba(255, 255, 255, 0.1)",
                        borderRadius: "4px",
                        position: "relative"
                      }}>
                        <Box sx={{
                          position: "absolute",
                          left: 0,
                          top: 0,
                          height: "100%",
                          width: `${((modalData?.score?.content || 0) / 90) * 100}%`,
                          bgcolor: "#00E676",
                          borderRadius: "4px"
                        }} />
                      </Box>
                    </div>

                    {/* Pronunciation Score Bar */}
                    <div style={{ marginBottom: "15px" }}>
                      <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "5px" }}>
                        <Typography sx={{ color: "#d1d5db", fontSize: "14px" }}>Pronun</Typography>
                        <Typography sx={{ color: "#d1d5db", fontSize: "14px" }}>{modalData?.score?.pronunciation || 0}/90</Typography>
                      </div>
                      <Box sx={{
                        height: "8px",
                        bgcolor: "rgba(255, 255, 255, 0.1)",
                        borderRadius: "4px",
                        position: "relative"
                      }}>
                        <Box sx={{
                          position: "absolute",
                          left: 0,
                          top: 0,
                          height: "100%",
                          width: `${((modalData?.score?.pronunciation || 0) / 90) * 100}%`,
                          bgcolor: "#00E676",
                          borderRadius: "4px"
                        }} />
                      </Box>
                    </div>

                    {/* Fluency Score Bar */}
                    <div style={{ marginBottom: "15px" }}>
                      <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "5px" }}>
                        <Typography sx={{ color: "#d1d5db", fontSize: "14px" }}>Fluency</Typography>
                        <Typography sx={{ color: "#d1d5db", fontSize: "14px" }}>{modalData?.score?.fluency || 0}/90</Typography>
                      </div>
                      <Box sx={{
                        height: "8px",
                        bgcolor: "rgba(255, 255, 255, 0.1)",
                        borderRadius: "4px",
                        position: "relative"
                      }}>
                        <Box sx={{
                          position: "absolute",
                          left: 0,
                          top: 0,
                          height: "100%",
                          width: `${((modalData?.score?.fluency || 0) / 90) * 100}%`,
                          bgcolor: "#00E676",
                          borderRadius: "4px"
                        }} />
                      </Box>
                    </div>
                  </div>

                  {/* Audio Players Section */}
                  <div style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
                    <div>
                      <Typography sx={{ color: "#d1d5db", fontSize: "14px", marginBottom: "8px" }}>Question</Typography>
                      <TextToSpeechPlayer text={modalData?.questionId?.audio_text || ""} />
                    </div>
                    <div>
                      <Typography sx={{ color: "#d1d5db", fontSize: "14px", marginBottom: "8px" }}>Response</Typography>
                      <AudioPlayer src={`${process.env.REACT_APP_BASE_URL}/${modalData?.answer_audio}`} />
                    </div>
                  </div>
                </div>

                {/* Right Section - Text Display */}
                <div style={{ 
                  flex: 1,
                  backgroundColor: "rgba(255, 255, 255, 0.03)",
                  borderRadius: "8px",
                  padding: "20px",
                  minHeight: "300px",
                  display: "flex",
                  alignItems: "center"
                }}>
                  <div style={{ wordBreak: "break-word" }}>
                    {(modalData?.module_subcategory?.includes('Describe Image') 
                      ? modalData?.questionId?.answer 
                      : modalData?.questionId?.audio_text)?.split(' ').map((word, index, array) => {
                      const spokenWords = modalData?.score?.text?.toLowerCase().split(' ') || [];
                      const isCorrect = spokenWords.includes(word.toLowerCase());
                      
                      return (
                        <span
                          key={index}
                          style={{
                            color: isCorrect ? "#4CAF50" : "#F44336",
                            marginRight: index === array.length - 1 ? 0 : "8px",
                            fontSize: "18px",
                            lineHeight: "1.6",
                            display: "inline-block"
                          }}
                        >
                          {word}
                        </span>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* Writing Essay UI */}
          {(modalData?.module_type?.includes('Writing') 
          || modalData?.module_type?.includes('Listening'))
          && 
          (modalData?.module_subcategory?.includes('Essay') ||
          modalData?.module_subcategory?.includes('Summarize Text') ||
          modalData?.module_subcategory?.includes('Write Email')
          ) && (
            <div className="writing-essay" style={{ 
              marginTop: "30px",
              padding: "20px",
              backgroundColor: "rgba(255, 255, 255, 0.05)",
              borderRadius: "8px",
              width: "100%",
              margin: "0 auto"
            }}>
              {/* Question Text */}
              <div style={{
                padding: "15px",
                backgroundColor: "rgba(255, 255, 255, 0.03)",
                borderRadius: "8px",
                marginBottom: "20px"
              }}>
                <Typography sx={{ 
                  color: "#d1d5db",
                  fontSize: "16px",
                  fontWeight: "500"
                }}>
                  {modalData?.questionId?.question_text}
                </Typography>
              </div>

              <div style={{ display: "flex", gap: "30px" }}>
                {/* Left Section - Scores */}
                <div style={{ flex: "0 0 300px" }}>
                  {/* Score Display */}
                  <Typography variant="h6" sx={{ 
                    color: "#d1d5db", 
                    marginBottom: "20px",
                    fontSize: "24px",
                    fontWeight: "500"
                  }}>
                    Score : {modalData?.score?.overall || 0}/{modalData?.score?.total || 9}
                  </Typography>

                  {/* Score Bars */}
                  <div style={{ marginBottom: "30px" }}>
                    {Object.entries(modalData?.score || {}).map(([key, value]) => {
                      // Skip overall and total properties as they're not score bars
                      if (key === 'overall' || key === 'total' || key === 'text') return null;

                      // Get max score for each category
                      const maxScore = {
                        content: 3,
                        form: 2,
                        grammar: 2,
                        vocabulary: 2,
                        spelling: 2,
                        linguistic: 2,
                        development: 2
                      }[key] || 2; // default to 2 if not specified


                      const maxScoreListening = {
                        content: 2,
                        form: 1,
                        grammar: 2,
                        vocabulary: 2,
                        spelling: 2
                      }[key] || 2; //

                      // Format the label
                      const label = {
                        content: 'Content',
                        form: 'Form',
                        grammar: 'Grammar',
                        vocabulary: 'Vocab',
                        spelling: 'Spelling',
                        linguistic: 'Linguist',
                        development: 'Develop'
                      }[key] || key;

                      return (
                        <div key={key} style={{ marginBottom: "15px" }}>
                          <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "5px" }}>
                            <Typography sx={{ color: "#d1d5db", fontSize: "14px" }}>{label}</Typography>
                            {
                              modalData?.module_type?.includes('Writing')&&
                              <Typography sx={{ color: "#d1d5db", fontSize: "14px" }}>{value}/{maxScore}</Typography>
                            }
                            {
                              modalData?.module_type?.includes('Listening')&&
                              <Typography sx={{ color: "#d1d5db", fontSize: "14px" }}>{value}/{maxScoreListening}</Typography>
                            }
                          </div>
                          <Box sx={{
                            height: "8px",
                            bgcolor: "rgba(255, 255, 255, 0.1)",
                            borderRadius: "4px",
                            position: "relative"
                          }}>
                            {
                              modalData?.module_type?.includes('Writing')&&
                              <Box sx={{
                                position: "absolute",
                                left: 0,
                                top: 0,
                                height: "100%",
                                width: `${(value / maxScore) * 100}%`,
                                bgcolor: "#00E676",
                                borderRadius: "4px"
                              }} />
                            }
                            {
                              modalData?.module_type?.includes('Listening')&&
                              <Box sx={{
                                position: "absolute",
                                left: 0,
                                top: 0,
                                height: "100%",
                                width: `${(value / maxScoreListening) * 100}%`,
                                bgcolor: "#00E676",
                                borderRadius: "4px"
                              }} />
                            }
                            
                          </Box>
                        </div>
                      );
                    })}
                  </div>
                </div>

                {/* Right Section - Essay Text */}
                <div style={{ 
                  flex: 1,
                  backgroundColor: "rgba(255, 255, 255, 0.03)",
                  borderRadius: "8px",
                  padding: "20px",
                  minHeight: "300px",
                  display: "flex",
                  alignItems: "center"
                }}>
                  <div style={{ wordBreak: "break-word" }}>
                    {modalData?.questionId?.answer?.split(' ').map((word, index, array) => {
                      const spokenWords = modalData?.score?.text?.toLowerCase().split(' ') || [];
                      const isCorrect = spokenWords.includes(word.toLowerCase());
                      
                      return (
                        <span
                          key={index}
                          style={{
                            color: isCorrect ? "#4CAF50" : "#F44336",
                            marginRight: index === array.length - 1 ? 0 : "8px",
                            fontSize: "18px",
                            lineHeight: "1.6",
                            display: "inline-block"
                          }}
                        >
                          {word}
                        </span>
                      );
                    })}
                  </div>
                </div>
              </div>

              {/* Suggestions Section */}
              {modalData?.suggestions && (
                <div style={{ 
                  marginTop: "20px",
                  padding: "15px",
                  backgroundColor: "rgba(255, 255, 255, 0.03)",
                  borderRadius: "8px"
                }}>
                  <Typography sx={{ 
                    color: "#d1d5db",
                    fontSize: "14px"
                  }}>
                    Suggestions: {modalData.suggestions}
                  </Typography>
                </div>
              )}
            </div>
          )}

          {/* RW Fill in Blanks UI */}
          {modalData?.module_subcategory?.includes('RW Fill in Blanks') && (
            <div style={{ marginBottom: "20px" }}>
              {/* Score Display at Top */}
              <Typography variant="h6" sx={{ 
                color: "#d1d5db", 
                marginBottom: "20px", 
                textAlign: "center",
                fontSize: "24px"
              }}>
                Score: {(() => {
                  try {
                    const userAnswers = JSON.parse(modalData.answers[0]);
                    const correctAnswers = JSON.parse(modalData.correctAnswers[0]);
                    return correctAnswers.filter((ans, idx) => ans === userAnswers[idx]).length;
                  } catch (e) {
                    return 0;
                  }
                })()}/{modalData.questionId.rwF_ans.length}
              </Typography>

              {/* Text with Blanks */}
              <div style={{
                backgroundColor: "rgba(255, 255, 255, 0.03)",
                borderRadius: "8px",
                padding: "24px",
                lineHeight: "2",
                fontSize: "16px",
                color: "#d1d5db"
              }}>
                {(() => {
                  const text = modalData.questionId.question_text;
                  const blanks = modalData.questionId.rwF;
                  const correctAnswers = JSON.parse(modalData.correctAnswers[0]);
                  const userAnswers = JSON.parse(modalData.answers[0]);

                  // Split text by blanks and create array of text and blanks
                  const parts = text.split('___').map((part, index) => {
                    if (index === text.split('___').length - 1) {
                      return part;
                    }

                    const userAnswer = userAnswers[index];
                    const correctAnswer = correctAnswers[index];
                    const isCorrect = userAnswer === correctAnswer;
                    const options = blanks[index];

                    return (
                      <>
                        {part}
                        <span style={{
                          padding: "4px 12px",
                          borderRadius: "4px",
                          backgroundColor: showAnswer 
                            ? "rgba(76, 175, 80, 0.2)"
                            : (isCorrect ? "rgba(76, 175, 80, 0.2)" : "rgba(244, 67, 54, 0.2)"),
                          border: `1px solid ${showAnswer 
                            ? "#4CAF50"
                            : (isCorrect ? "#4CAF50" : "#F44336")}`,
                          color: showAnswer
                            ? "#4CAF50"
                            : (isCorrect ? "#4CAF50" : "#F44336"),
                          margin: "0 4px",
                          display: "inline-block"
                        }}>
                          {showAnswer ? correctAnswer : (userAnswer || '')}
                        </span>
                      </>
                    );
                  });

                  return parts;
                })()}
              </div>

              {/* See Answer Toggle */}
              <div style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginTop: "20px",
                padding: "0 24px"
              }}>
                <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                  <Typography sx={{ color: "#d1d5db", fontSize: "14px" }}>
                    See Answer
                  </Typography>
                  <div className="form-check form-switch">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      role="switch"
                      id="seeAnswerSwitch"
                      checked={showAnswer}
                      onChange={(e) => setShowAnswer(e.target.checked)}
                      style={{
                        width: "40px",
                        height: "20px",
                        cursor: "pointer",
                        backgroundColor: showAnswer ? "#4CAF50" : "#d1d5db"
                      }}
                    />
                  </div>
                </div>

                {/* Report Question Button */}
                <button
                  style={{
                    backgroundColor: "transparent",
                    border: "1px solid #F44336",
                    color: "#F44336",
                    padding: "4px 12px",
                    borderRadius: "4px",
                    cursor: "pointer",
                    fontSize: "14px"
                  }}
                >
                  Report Question
                </button>
              </div>
            </div>
          )}

          {/* Fill in The Blanks UI */}
          {(modalData?.module_subcategory?.includes('Fill in The Blanks')
          || modalData?.module_subcategory?.includes('Fill in Blanks'))
          && (
            <div style={{ marginBottom: "20px" }}>
              {/* Score Display at Top */}
              <Typography variant="h6" sx={{ 
                color: "#d1d5db", 
                marginBottom: "20px", 
                textAlign: "center",
                fontSize: "24px"
              }}>
                Score: {(() => {
                  try {
                    const answers = JSON.parse(modalData.answers[0]);
                    return Object.values(answers).filter(val => val === true).length;
                  } catch (e) {
                    return 0;
                  }
                })()}/{modalData.questionId.FillInTheBlanksAnswers.length}
              </Typography>

              {/* Text with Blanks */}
              <div style={{
                backgroundColor: "rgba(255, 255, 255, 0.03)",
                borderRadius: "8px",
                padding: "24px",
                lineHeight: "2",
                fontSize: "16px",
                color: "#d1d5db"
              }}>
                {(() => {
                  const text = modalData.questionId.question_text;
                  const blanks = modalData.questionId.FillInTheBlanksAnswers;
                  const userAnswers = (() => {
                    try {
                      return JSON.parse(modalData.answers[0]);
                    } catch (e) {
                      return {};
                    }
                  })();

                  // Split text by blanks and create array of text and blanks
                  const parts = text.split('____').map((part, index) => {
                    if (index === text.split('____').length - 1) {
                      return part;
                    }
                    
                    const isCorrect = userAnswers[`blank${index + 1}`];
                    const answer = blanks[index];

                    return (
                      <>
                        {part}
                        <span style={{
                          padding: "4px 12px",
                          borderRadius: "4px",
                          backgroundColor: showAnswer || isCorrect ? "rgba(76, 175, 80, 0.2)" : "transparent",
                          border: `1px solid ${showAnswer || isCorrect ? "#4CAF50" : "#d1d5db"}`,
                          color: showAnswer || isCorrect ? "#4CAF50" : "#d1d5db",
                          margin: "0 4px",
                          display: "inline-block"
                        }}>
                          {answer}
                        </span>
                      </>
                    );
                  });

                  return parts;
                })()}
              </div>

              {/* See Answer Toggle */}
              <div style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginTop: "20px",
                padding: "0 24px"
              }}>
                <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                  <Typography sx={{ color: "#d1d5db", fontSize: "14px" }}>
                    See Answer
                  </Typography>
                  <div className="form-check form-switch">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      role="switch"
                      id="seeAnswerSwitch"
                      checked={showAnswer}
                      onChange={(e) => setShowAnswer(e.target.checked)}
                      style={{
                        width: "40px",
                        height: "20px",
                        cursor: "pointer",
                        backgroundColor: showAnswer ? "#4CAF50" : "#d1d5db"
                      }}
                    />
                  </div>
                </div>


              </div>
            </div>
          )}

          {/* Incorrect Words UI */}
          {modalData?.module_subcategory?.includes('Incorrect Words') && (
            <div style={{ 
              marginTop: "30px",
              padding: "20px",
              backgroundColor: "rgba(255, 255, 255, 0.05)",
              borderRadius: "8px",
              width: "100%",
              margin: "0 auto"
            }}>
              {/* Score Display */}
              <Typography variant="h6" sx={{ 
                color: "#d1d5db", 
                marginBottom: "20px", 
                textAlign: "center",
                fontSize: "24px"
              }}>
                Score: {(() => {
                  try {
                    const userAnswers = JSON.parse(modalData.answers[0]);
                    const correctAnswers = JSON.parse(modalData.correctAnswers[0]);
                    return `${correctAnswers.length}/${modalData.questionId.mcq_ans.length}`;
                  } catch (e) {
                    return "0/0";
                  }
                })()}
              </Typography>

              {/* Text Display with Highlighted Words */}
              <div style={{
                backgroundColor: "rgba(255, 255, 255, 0.03)",
                borderRadius: "8px",
                padding: "24px",
                lineHeight: "2",
                fontSize: "16px",
                color: "#d1d5db"
              }}>
                {(() => {
                  try {
                    const text = modalData.questionId.question_text;
                    const words = text.split(' ');
                    const userAnswers = JSON.parse(modalData.answers[0]).filter(index => index !== null);
                    const correctAnswers = modalData.questionId.mcq_ans.map(index => parseInt(index));

                    return words.map((word, index) => {
                      const isUserSelected = userAnswers.includes(index);
                      const isCorrectAnswer = correctAnswers.includes(index);

                      // Determine the styling
                      let style = {
                        display: 'inline-block',
                        margin: '0 4px'
                      };

                      // Show both user selections and correct answers
                      if (isCorrectAnswer) {
                        style = {
                          ...style,
                          backgroundColor: "rgba(76, 175, 80, 0.2)",
                          padding: "4px 8px",
                          borderRadius: "4px",
                          color: "#4CAF50"
                        };
                      } else if (isUserSelected) {
                        style = {
                          ...style,
                          backgroundColor: "rgba(244, 67, 54, 0.2)",
                          padding: "4px 8px",
                          borderRadius: "4px",
                          color: "#F44336"
                        };
                      }

                      return (
                        <span key={index} style={style}>
                          {word}
                        </span>
                      );
                    });
                  } catch (e) {
                    return modalData?.questionId?.question_text || "No text available";
                  }
                })()}
              </div>
            </div>
          )}

          <div className="d-flex justify-content-between mt-4">
            {modalData.feedback ? (
              <p>
                {modalData.feedback && "Feedback -"} {modalData.feedback}
              </p>
            ) : (
              <p>
                {feedback && "Feedback -"} {feedback}
              </p>
            )}

            {feedbutton === true && modalData.feedback === "" && (
              <button
                className="btn btn-success"
                onClick={() => setFeedbackModal(true)}
              >
                Add Feedback
              </button>
            )}
          </div>
        </div>
      </Modal>

      <Modal
        isOpen={feedbackModal}
        onRequestClose={() => {
          setFeedbackModal(false);
          setfeedback("");
          setFeedbackButton(true);
        }}
        contentLabel="Read Aloud Modal"
        style={{
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "#2d2f3a",
            color: "#d1d5db",
            borderRadius: "8px",
            padding: "20px",
            width: "70%",
          },
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.75)",
          },
        }}
      >
        <div style={{ textAlign: "center" }}>
          <div className="d-flex justify-content-between">
            <Typography
              variant="h5"
              sx={{ color: "#d1d5db", marginBottom: "20px" }}
            >
              Add Feedback
            </Typography>

            <IconButton>
              <CloseOutlined
                onClick={() => setFeedbackModal(false)}
                sx={{
                  color: "red",
                }}
              />
            </IconButton>
          </div>

          <div className="d-flex align-items-center justify-content-evenly mt-3">
            <textarea
              className="w-100 bg-transparent text-white"
              name=""
              id=""
              rows={9}
              resize="none"
              onChange={(e) => setfeedback(e.target.value)}
            />
          </div>

          <div className="d-flex justify-content-end mt-4">
            <button
              className="btn btn-success"
              onClick={() => submitFeedback()}
            >
              Submit Feedback
            </button>
          </div>
        </div>
      </Modal>
    </Card>
  );
}

export default BillingInformation;